<i18n>
{
  "en": {
    "local": {
      "msg": {
        "loading": "Loading file…"
      }
    }
  },
  "fr": {
    "local": {
      "msg": {
        "loading": "Ouverture du fichier …"
      }
    }
  }
}
</i18n>

<template>
  <div class="fill-height spreadsheet-root" ref="xspreadsheetRoot">
    <!-- animation d'attente pour le chargement du fichier -->
    <div v-show="loading">
      <v-progress-linear active indeterminate />
      <p class="text-center pt-3">{{ $t("local.msg.loading") }}</p>
    </div>

    <!-- emplacement du composant tableur -->
    <div ref="xspreadsheet" class="spreadsheet-editor"></div>

    <!-- menu contextuel personnalisable sur les cellules,
      seulement dans le cas où le fichier Excel est en lecture seule
      (si le fichier est éditable, on affiche le menu contextuel de XSpreadsheet) -->

    <v-menu
      v-show="!disabled && readonly"
      v-model="contextmenuCell.visible"
      :position-x="contextmenuCell.position.x"
      :position-y="contextmenuCell.position.y"
      absolute
      eager
      close-on-click
      close-on-content-click
      z-index="1000"
    >
      <!-- div with id to get the v-menu content -->
      <div id="contextmenuCell">
        <slot name="contextmenuCell" :cellrange="contextmenuCell.cellrange">
        </slot>
      </div>
    </v-menu>

    <!-- menu contextuel personnalisable sur les noms d'onglets,
      seulement dans le cas où le fichier Excel est en lecture seule
      (si le fichier est éditable, on affiche le menu contextuel de XSpreadsheet) -->
    <v-menu
      v-show="!disabled && readonly"
      v-model="contextmenuSheetTab.visible"
      :position-x="contextmenuSheetTab.position.x"
      :position-y="contextmenuSheetTab.position.y"
      absolute
      close-on-click
      close-on-content-click
      z-index="1000"
    >
      <slot
        name="contextmenuSheetTab"
        :active="activeSheetName === contextmenuSheetTab.sheetName"
        :sheetName="contextmenuSheetTab.sheetName"
      >
      </slot>
    </v-menu>
  </div>
</template>

<script>
import Spreadsheet from "@/model/spreadsheet/spreadsheetpatched";

import fr from "@/model/spreadsheet/locale/fr.js";
import en from "@/model/spreadsheet/locale/en.js";

function getKeyName(keyText) {
  switch (keyText) {
    case "Delete":
      return "suppr";
    case "1":
      return keyText;
    default:
      // touche non utilisée dans les raccourcis
      // compléter le switch ci-dessus selon les besoins
      return null;
  }
}

function getKeyEventName(keyboardEvent) {
  const keyPart = getKeyName(keyboardEvent.key);

  if (!keyPart) {
    return null;
  }

  const ctrlPart = keyboardEvent.ctrlKey ? "ctrl" : null;
  return ["key", ctrlPart, keyPart].filter((v) => !!v).join("-");
}

/**
 * Supprime tous les noeuds enfants d'un élément du DOM.
 */
function removeAllChildrenInDOM(node) {
  while (node.firstChild) {
    node.removeChild(node.lastChild);
  }
}

export default {
  name: "spreadsheet-editor",

  props: {
    /**
     * Disables context menu and keyboard shorcuts.
     * To disable file contents modification, use `readonly` instead.
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Indique que le fichier Excel est en cours de chargement.
     * Cela affiche une animation d'attente à la place du composant tableur.
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxCol: {
      type: Number,
      default: 26,
    },

    maxRow: {
      type: Number,
      default: 100,
    },

    /**
     * Indicates whether the spreadsheet should be read-only or editable.
     * Defaults to editable.
     */
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    triggerRender: Boolean,

    /**
     * List of spreadsheets data to display.
     * If not set, the component is not visible.
     * Can be accessed both ways with `v-model`.
     * @see https://www.npmjs.com/package/x-data-spreadsheet
     */
    value: {
      type: Array,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      activeSheetName: null,
      // Menu contextuel personnalisable (hors édition du fichier Excel)
      // affiché sur clic droit sur une sélection de cellules.
      // Il y a toujours au moins une cellule sélectionnée dans l'onglet actif.
      contextmenuCell: {
        // cellules concernées par l'action sélectionnée dans le menu
        cellrange: {
          sheetName: null,
          startRowIndex: 0,
          startColIndex: 0,
          endRowIndex: 0,
          endColIndex: 0,
        },
        // affichage du menu contextuel
        position: {
          x: 0,
          y: 0,
        },
        visible: false,
      },

      // Menu contextuel personnalisable (hors édition du fichier Excel)
      // affiché sur clic droit sur un nom d'onglet, actif ou non.
      contextmenuSheetTab: {
        // Nom de l'onglet sur lequel l'utilisateur a cliqué
        sheetName: null,
        // affichage du menu contextuel
        position: {
          x: 0,
          y: 0,
        },
        visible: false,
      },
      grid: null, // initialisée dans le mounted parce qu'on a besoin de la div,
      xspreadsheetRoot: null,
    };
  },

  watch: {
    disabled() {
      this.grid.options.disabled = this.disabled;
    },
    loading() {
      // Cesse d'afficher le fichier actuel en cas de chargement d'un nouveau fichier.
      // ex : changement d'état dans le paramétrage ad hoc
      if (this.loading) {
        this.destroyGrid();
      }
    },
    triggerRender() {
      if (this.triggerRender) {
        if (this.grid && this.xspreadsheetRoot.clientHeight) {
          this.$refs.xspreadsheet.style.maxHeight = `${this.xspreadsheetRoot.clientHeight}px`;
          this.grid.sheet.reload();
        }
        this.$emit("update:triggerRender", false);
      }
    },
    value() {
      // destroy grid if no file to display
      if (!this.value) {
        this.destroyGrid();
        return;
      }

      let grid = this.getGrid();
      grid.setMaxRow(this.maxRow);
      grid.setMaxCol(this.maxCol);

      // We user JSON.parse(JSON.stringify()) to clone the value
      grid.loadData(JSON.parse(JSON.stringify(this.value))).reRender();

      // réactive l'onglet précédemment affiché, s'il est encore présent
      this.setGridActiveSheet(this.activeSheetName);

      // rétablit les gestionnaires d'évènement pour le menu contextuel
      // personnalisé sur les noms d'onglets.
      if (this.readonly) {
        this.getGridSheetTabsElements().forEach((htmlElement) =>
          htmlElement.addEventListener("contextmenu", (mouseEvent) => {
            const sheetName = htmlElement.innerText;
            this.onContextMenuSheetTab(sheetName, mouseEvent);
          })
        );
      }
    },
  },

  mounted() {
    this.disabledContextMenuCellFromVMenuContent();
    this.xspreadsheetRoot = this.$refs.xspreadsheetRoot;
  },

  methods: {
    disabledContextMenuCellFromVMenuContent() {
      // Chrome have a lot of v-menu__content...
      let elements = document.getElementsByClassName("v-menu__content");
      for (let vmenu of elements) {
        vmenu.addEventListener("contextmenu", (event) =>
          event.preventDefault()
        );
      }
    },

    /**
     * Détruit l'objet XSpreadSheet et supprime les éléments qu'il a insérés dans le DOM.
     * Cela rend efface tout le tableur de l'écran.
     */
    destroyGrid() {
      this.grid = null;
      removeAllChildrenInDOM(this.$refs.xspreadsheet);
    },
    getActiveSheetName() {
      return this.grid.sheet.data.name;
    },
    getGrid() {
      if (!this.grid) {
        // Fix the bug where the XSpreadsheet canvas height increased
        // when scrolling up and down in the sheet.
        this.$refs.xspreadsheet.style.maxHeight =
          this.xspreadsheetRoot.clientHeight + "px";
        if (this.$store.state.locale === "fr") {
          Spreadsheet.locale(this.$store.state.localeCode, fr);
        } else {
          Spreadsheet.locale(this.$store.state.localeCode, en);
        }

        this.grid = new Spreadsheet(this.$refs.xspreadsheet, {
          disabled: this.disabled,
          mode: this.readonly ? "read" : "edit",
          col: {
            len: this.maxCol,
            width: 100,
            indexWidth: 60,
            minWidth: 60,
          },
          row: {
            len: this.maxRow,
            height: 25,
          },
          showToolbar: false,
          showContextmenu: !this.readonly,
          view: {
            height: this.getRootElementGetter("clientHeight"),
            width: this.getRootElementGetter("clientWidth"),
          },
        })
          .change(this.onChange)
          .on("cell-edit", this.onEditCell)
          .on("cell-selected", this.onSelectCell)
          .on("cells-selected", this.onSelectRange)
          .on("contextmenu", this.onContextMenuCell)
          .on("copy", this.onCopy)
          .on("cut", this.onCut)
          .on("key", this.onKey)
          .on("paste", this.onPaste);

        // event listener on sheet addition/removal
        this.getGridBottomBarElement().addEventListener(
          "click",
          this.onBottomBarClick
        );
      }
      return this.grid;
    },
    /**
     * Returns the XSpreadsheet's bottom bar (which contains the sheet tabs & new sheet button)
     * @returns HTMLElement
     */
    getGridBottomBarElement() {
      return this.$refs.xspreadsheet.getElementsByClassName(
        "x-spreadsheet-bottombar"
      )[0];
    },
    /**
     * Returns the XSpreadsheet's tab to display the request sheet in the workbook,
     * or undefined if it doesn't exist.
     * @param String sheetName Sheet name (unique in the workbook)
     * @returns HTMLElement
     */
    getGridSheetTabElement(sheetName) {
      return this.getGridSheetTabsElements().find(
        (sheetTabElement) => sheetTabElement.innerText === sheetName
      );
    },
    /**
     * Returns the HTML elements for XSpreadsheet's sheet tabs.
     * @param String sheetName Sheet name (unique in the workbook)
     * @returns HTMLElement
     */
    getGridSheetTabsElements() {
      const sheetTabs =
        this.getGridBottomBarElement().getElementsByClassName(
          "x-spreadsheet-menu"
        )[0].children; // liste des onglets // HTMLCollection (not array !)

      return [...sheetTabs];
    },
    getNewSheetNameList() {
      return this.grid.getData().map((sheet) => sheet.name);
    },
    getRootElementGetter(propName) {
      return () => this.getRootElementProperty(propName);
    },
    getRootElementProperty(propName) {
      return this.xspreadsheetRoot
        ? this.xspreadsheetRoot[propName]
        : undefined;
    },
    onBottomBarClick() {
      this.activeSheetName = this.getActiveSheetName();
      let oldSheetList = this.value.map((sheet) => sheet.name);
      let newSheetList = this.getNewSheetNameList();

      if (!this.arraysHaveSameContent(oldSheetList, newSheetList)) {
        this.onChange();
      }
    },

    // Renvoie un booléen indiquant si les deux tableaux ont le même contenu (dans le même ordre)
    arraysHaveSameContent(a1, a2) {
      return a1.length === a2.length && a1.every((v, i) => a2[i] === v);
    },

    onContextMenuCell(infoMouse, infoGrid) {
      const cellrange = {
        sheetName: this.activeSheetName,
        ...infoGrid,
      };
      this.contextmenuCell = {
        cellrange,
        position: infoMouse,
        visible: true,
      };
    },
    onContextMenuSheetTab(sheetName, mouseEvent) {
      this.contextmenuSheetTab = {
        sheetName,
        position: {
          x: mouseEvent.clientX,
          y: mouseEvent.clientY,
        },
        visible: true,
      };
    },
    onCopy(infoGrid) {
      this.$emit("copy", this.toCellRange(infoGrid));
    },
    onCut(infoGrid) {
      this.$emit("cut", this.toCellRange(infoGrid));
    },
    // Appellée lorsque l'utilisateur double-clique sur une cellule ou plage de cellule.
    // Cet évènement est déclenché même si le fichier Excel est en lecture seule.
    onEditCell(infoGrid) {
      if (!this.disabled) {
        // console.log("onEditCell", infoGrid);
        this.$emit("editCell", this.toCellRange(infoGrid));
      }
    },
    onKey(keyboardEvent, infoGrid) {
      if (this.disabled || !this.readonly) {
        return;
      }

      // Pour faciliter l'implémentation des raccourcis clavier,
      // le nom de l'évènement dépend des touches pressées :
      //  @key-suppr
      //  @key-ctrl-1 (fonctionne pour les chiffres du clavier et du pavé numérique)
      const eventName = getKeyEventName(keyboardEvent);
      if (eventName) {
        keyboardEvent.preventDefault();

        const cellrange = this.toCellRange(infoGrid);
        this.$emit(eventName, cellrange);
      }
    },
    toCellRange(infoGrid) {
      return {
        sheetName: this.activeSheetName,
        ...infoGrid,
      };
    },
    onPaste(infoGrid) {
      this.$emit("paste", this.toCellRange(infoGrid));
    },
    onChange() {
      // We user JSON.parse(JSON.stringify()) to clone the data
      let workbook = JSON.parse(JSON.stringify(this.grid.getData()));

      // clean dummy param (added by xspreadsheet)
      for (let sheet of workbook) {
        delete sheet.autofilter;
        delete sheet.cols.len;
        delete sheet.freeze;
        delete sheet.rows.len;
      }

      this.$emit("input", workbook);
    },
    onSelectCell(xCell, rowIndex, columnIndex) {
      // XSpreadSheet émet aussi cet évènement quand il n'y a plus de sélection
      if (
        rowIndex !== null &&
        columnIndex !== null &&
        rowIndex !== "null" &&
        columnIndex !== "null"
      ) {
        const cellrange = {
          sheetName: this.activeSheetName,
          startRowIndex: rowIndex,
          startColIndex: columnIndex,
          endRowIndex: rowIndex,
          endColIndex: columnIndex,
        };
        this.$emit("selectCell", cellrange);
      }
    },
    onSelectRange(xCell, range) {
      // console.log('SpreadsheetEditor onSelectRange', range)
      const cellrange = {
        sheetName: this.activeSheetName,
        startRowIndex: range.sri,
        startColIndex: range.sci,
        endRowIndex: range.eri,
        endColIndex: range.eci,
      };
      this.$emit("selectCell", cellrange);
    },

    scrollToCursor() {
      this.grid.sheet.scrollToCursor();
    },

    /**
     * Displays the requested sheet in the grid.
     * Has no effect if the sheet doesn't exist in the workbook.
     * @param String sheetName Sheet name (unique in the workbook)
     */
    setGridActiveSheet(sheetName) {
      let sheetTabElement = this.getGridSheetTabElement(sheetName);
      if (sheetTabElement) {
        sheetTabElement.click();
        this.activeSheetName = sheetName;
      } else {
        this.activeSheetName = this.getActiveSheetName();
      }
    },
    /**
     * PUBLIC
     * Reset the selection to a single cell on the active sheet.
     */
    setActiveCell(rowIndex, colIndex) {
      this.grid.sheet.setActiveCell(rowIndex, colIndex);
    },
    /**
     * PUBLIC
     * Reset the selection to a cell range on the active sheet.
     */
    setActiveCellRange(cellrange) {
      this.grid.sheet.setActiveCellRange(cellrange);
    },
    /**
     * PUBLIC
     * Set the background color for the currently selected cell.
     */
    setActiveCellBackgroundColor(bgColor) {
      this.grid.sheet.toolbar.change("bgcolor", bgColor);
      this.grid.reRender();
    },

    /**
     * PUBLIC
     * Reset a cell text, with the cell belonging to the active sheet.
     */
    setCellTextOnActiveSheet(rowIndex, colIndex, newCellText) {
      const sheetIndex = this.value.findIndex(
        (sheet) => sheet.name === this.activeSheetName
      );
      if (sheetIndex === -1) {
        console.warn(
          "SpreadsheetEditor.setCellTextOnActiveSheet: couldn't find active sheet in file data."
        );
      } else {
        this.grid.cellText(rowIndex, colIndex, newCellText, sheetIndex);
        this.grid.reRender();
      }
    },
  },
};
</script>
