import i18n from "../i18n";

export function createInvalidGraphError(e) {
  switch (e.message) {
    case "CYCLE_DETECTED":
      return i18n.t("global.business-process.cycle-detected");
    case "DUPLICATE_VERTEX_ID":
      return i18n.t("global.business-process.duplicate-vertex-id", [
        e.arguments[0],
      ]);
    case "DUPLICATE_EDGE_ID":
      return i18n.t("global.business-process.duplicate-edge-id", [
        e.arguments[0],
      ]);
    case "INVALID_EDGE_VERTEX_REF":
      return i18n.t("global.business-process.invalid-edge-vertex-ref", [
        e.arguments[0],
        e.arguments[1],
      ]);
    case "MISSING_EDGE_ID":
      return i18n.t("global.business-process.missing-edge-id");
    case "MISSING_VERTEX_NAME":
      return i18n.t("global.business-process.missing-vertex-name", [
        e.arguments[0],
      ]);
    case "MISSING_VERTEX_ID":
      return i18n.t("global.business-process.missing-vertex-id");
    case "MISSING_EDGE_FROM":
      return i18n.t("global.business-process.missing-edge-from", [
        e.arguments[0],
      ]);
    case "MISSING_EDGE_TO":
      return i18n.t("global.business-process.missing-edge-to", [
        e.arguments[0],
      ]);
    case "NO_VERTICES":
      return i18n.t("global.business-process.no-vertices");
    default:
      return e;
  }
}

export function formatDate(date) {
  if (!date) return "";
  return Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date));
}

/**
 * Compares two software version numbers (e.g. "1.7.1" or "1.2b").
 *
 * This function was born in http://stackoverflow.com/a/6832721.
 *
 * @param {string} v1 The first version to be compared.
 * @param {string} v2 The second version to be compared.
 * @param {object} [options] Optional flags that affect comparison behavior:
 * <ul>
 *     <li>
 *         <tt>lexicographical: true</tt> compares each part of the version strings lexicographically instead of
 *         naturally; this allows suffixes such as "b" or "dev" but will cause "1.10" to be considered smaller than
 *         "1.2".
 *     </li>
 *     <li>
 *         <tt>zeroExtend: true</tt> changes the result if one version string has less parts than the other. In
 *         this case the shorter string will be padded with "zero" parts instead of being considered smaller.
 *     </li>
 * </ul>
 * @returns {number|NaN}
 * <ul>
 *    <li>0 if the versions are equal</li>
 *    <li>a negative integer iff v1 < v2</li>
 *    <li>a positive integer iff v1 > v2</li>
 *    <li>NaN if either version string is in the wrong format</li>
 * </ul>
 *
 * @copyright by Jon Papaioannou (["john", "papaioannou"].join(".") + "@gmail.com")
 * @license This function is in the public domain. Do what you want with it, no strings attached.
 */
export function versionCompare(v1, v2, options) {
  var lexicographical = options && options.lexicographical,
    zeroExtend = options && options.zeroExtend,
    v1parts = v1.split("."),
    v2parts = v2.split(".");

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0");
    while (v2parts.length < v1parts.length) v2parts.push("0");
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return 1;
    }

    if (v1parts[i] == v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1;
  }

  return 0;
}

export function tableHeight(scale, numItems, hasFilters, tableEl) {
  if (numItems === 0) {
    // If there are no items, we still want to show the "no data available" message
    numItems = 1;
  }

  let itemsHeight = 32 * (Number(numItems) + 1); // size of a dense line 32px; size of the header 32px

  // Check whether the table has a horizontal scrollbar
  if (tableEl && tableEl.scrollWidth > tableEl.clientWidth) {
    itemsHeight += 11; // size of the horizontal scrollbar
  }

  if (hasFilters) {
    return `min(calc(${scale}vh - var(--headerHeight) - var(--filtersHeight)), ${itemsHeight}px)`;
  }
  return `min(calc(${scale}vh - var(--headerHeight)), ${itemsHeight}px)`;
}

export function isExcelFile(fileName) {
  return (
    fileName?.endsWith(".xls") ||
    fileName?.endsWith(".xlsx") ||
    fileName?.endsWith(".xlsm")
  );
}

export function createIFrameUrls(data) {
  const result = [];

  data.forEach((iframeSrc) => {
    if (!iframeSrc) return;
    const byteCharacters = atob(iframeSrc.workbook);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: "text/html",
    });
    const blobUrl = URL.createObjectURL(blob);
    result.push({
      name: iframeSrc.name,
      workbook: blobUrl,
    });
  });
  return result;
}
