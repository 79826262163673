import Bottombar from "x-data-spreadsheet/src/component/bottombar";
import Spreadsheet from "./spreadsheet";
import Validator from "x-data-spreadsheet/src/core/validator";
import { h } from "x-data-spreadsheet/src/component/element";

// ----------------------------------
// Patch for x-data-spreadsheet 1.1.9
// Fix list
// ----------------------------------

// [FIX][editor.js.constructor]: Change value of the data when we select a value from the combo list validation -- When a suggestItem is click => call the event this.change('input', it)
// [FIX][editor.js.constructor]: Display the combo list validation when click on editing cell -- When click on textarea in cell editing mode display the suggest list
// [FIX][editor.js.resetTextareaSize]: Manage the width and height of the text area only if contains /n at the end => When editing a cell the style is remove from the error in inputText.split('\n')
// [FIX][suggest.js.constructor] add stop propagation on mousewhell event so that suggest items scrollbar got priority
// [FIX][table.js.renderCell] Number type will be with align right
// [FIX][table.js.tableGridStyle]: Fix grid border color to transparent

// 04/04/2022
// [FIX][formula.js.baseFormulas, locale]: Add NOW formula
// [FIX][cell.js[evalSubExpr, evalSuffixExpr], helper.js [numberCalc]]: Add & operator
// [FIX][cell.js.evalSuffixExpr] Fix empty cell in formula
// [FIX][cell.js.infixExprToSuffixExpr]: Manage formula contening another formula - Mange formula with zero or one parameter
// [FIX][formula.js.baseFormulas, locale]: Add COUNTA formula
// [FIX][cell.js.evalSuffixExpr]: Fix case =+A1
// [FIX][cell.js.infixExprToSuffixExpr] correct FORMULAS((A1+A2),...)

// 30/05/2022
// [FIX][suggest.js.constructor] fix suggest width
// [FIX][formula.js] Fix formula order by alphabetical

// 27/06/2022
// [FIX][editor.js]: resize text area according to the scrollHeight or rowHeight
// [FIX][sheet.js, horizontalScrollbarSet, verticalScrollbarSet] Reset scrollbar when tabs changed
// [FIX, css] vertical scroll bar set to 100% for flexibility
// [FIX][helper.js, equals]: Fix equals null when setting color style

// 04/07/2022
// [FIX][sheet.js] Emit copy & paste events
// [FIX][sheet.js] Allow setting active cell from client code

// 01/12/2022
// [FIX][table.js][cell.js] PCRRV9-2414: Add checking for circular dependance
// [FIX][sheet.js] Emit cut event
// [FIX][sheet.js] Emit key event
// [FIX][sheet.js] Emit contextmenu event (when read-only)

// 25/27/2023
// [FIX][sheet.js][PCRRV9-2766] Add Method to center the scroll to the cursor

// [FIX]: Add set for max column and max row to re-render correctly the grid
Spreadsheet.prototype.setMaxCol = function (value) {
  this.options.col.len = value;
};
Spreadsheet.prototype.setMaxRow = function (value) {
  this.options.row.len = value;
};

// [FIX]: Combo list (validations) to use an array of string in case of string
Validator.prototype.values = function () {
  return this.value;
};

// [FIX][TEMPORARY]: Bottombar => Disable remove and rename - hide the add button [see css on .x-spreadsheet-bottombar .x-spreadsheet-icon]
Bottombar.prototype.addItem = function (name, active) {
  this.dataNames.push(name);
  const item = h("li", active ? "active" : "").child(name);
  item.on("click", () => {
    this.clickSwap2(item);
  }); /* .on('contextmenu', (evt) => {
    const { offsetLeft, offsetHeight } = evt.target
    this.contextMenu.setOffset({ left: offsetLeft, bottom: offsetHeight + 1 })
    this.deleteEl = item
  })
  */
  if (active) {
    this.clickSwap(item);
  }
  this.items.push(item);
  this.menuEl.child(item);
  this.moreEl.reset(this.dataNames);
};

export default Spreadsheet;
