import { tf } from "x-data-spreadsheet/src/locale/locale";

const formatStringRender = (v) => v;

const formatNumberRender = (v, prefix, suffix) => {
  // match "-12.1" or "12" or "12.1"
  if (v && /^(-?\d+.?\d*)$/.test(v)) {
    const v1 = Number(v).toFixed(2).toString();
    const [first, ...parts] = v1.split("\\.");
    let formatted = [first.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"), ...parts];
    if (prefix) {
      formatted = prefix + formatted;
    }
    if (suffix) {
      formatted += suffix;
    }
    return formatted;
  }
  return v;
};

const formatPercentageRender = (v) => {
  // match "-12.1" or "12" or "12.1"
  if (v && /^(-?\d+.?\d*)$/.test(v)) {
    return formatNumberRender(100 * v, undefined, "%");
  }
  return v;
};

const formatDateRender = (excelDate) => {
  if (!excelDate || !/^\d+$/.test(excelDate)) {
    return excelDate;
  }
  // Excel dates are the number of days since 1900-01-00, which is technically 1899-12-30.
  // However, Excel incorrectly treats 1900 as a leap year, so we need to adjust for that.
  const baseDate = new Date(1899, 11, 30); // 1899-12-30

  // Adjust for the 1900 leap year error.
  if (excelDate < 60) {
    excelDate--;
  }

  const milliseconds = baseDate.getTime() + excelDate * 24 * 60 * 60 * 1000;
  const jsDate = new Date(milliseconds);

  const day = String(jsDate.getDate()).padStart(2, "0");
  const month = String(jsDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = jsDate.getFullYear();

  return `${day}/${month}/${year}`;
};

const baseFormats = [
  {
    key: "normal",
    title: tf("format.normal"),
    type: "string",
    render: formatStringRender,
  },
  {
    key: "text",
    title: tf("format.text"),
    type: "string",
    render: formatStringRender,
  },
  {
    key: "number",
    title: tf("format.number"),
    type: "number",
    label: "1,000.12",
    render: formatNumberRender,
  },
  {
    key: "percent",
    title: tf("format.percent"),
    type: "number",
    label: "10.12%",
    render: formatPercentageRender,
  },
  {
    key: "rmb",
    title: tf("format.rmb"),
    type: "number",
    label: "￥10.00",
    render: (v) => formatNumberRender(v, "￥"),
  },
  {
    key: "usd",
    title: tf("format.usd"),
    type: "number",
    label: "$10.00",
    render: (v) => formatNumberRender(v, "$"),
  },
  {
    key: "eur",
    title: tf("format.eur"),
    type: "number",
    label: "€10.00",
    render: (v) => formatNumberRender(v, "€"),
  },
  {
    key: "date",
    title: tf("format.date"),
    type: "date",
    label: "26/09/2008",
    render: formatDateRender,
  },
  {
    key: "time",
    title: tf("format.time"),
    type: "date",
    label: "15:59:00",
    render: formatStringRender,
  },
  {
    key: "datetime",
    title: tf("format.datetime"),
    type: "date",
    label: "26/09/2008 15:59:00",
    render: formatStringRender,
  },
  {
    key: "duration",
    title: tf("format.duration"),
    type: "date",
    label: "24:01:00",
    render: formatStringRender,
  },
];

// const formats = (ary = []) => {
//   const map = {};
//   baseFormats.concat(ary).forEach((f) => {
//     map[f.key] = f;
//   });
//   return map;
// };
const formatm = {};
baseFormats.forEach((f) => {
  formatm[f.key] = f;
});

export default {};
export { formatm, baseFormats };
