var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.showError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('v-row',{staticClass:"my-1",staticStyle:{"height":"58px"}},[_c('v-col',{attrs:{"cols":"8"}},[(_vm.$store.state.bpInstModifyPermission && !_vm.locked)?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.allowedActions?.canRetry,"color":"primary"},on:{"click":_vm.retry}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" pic-refresh ")]),_vm._v(" "+_vm._s(_vm.$t("global.action.retry"))+" ")],1):_vm._e(),(
          _vm.$store.state.bpInstModifyPermission &&
          !_vm.locked &&
          _vm.taskType === 'runCreation'
        )?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.allowedActions?.canRetryAndStart,"color":"primary"},on:{"click":_vm.retryAndStart}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" pic-play-fill ")]),_vm._v(" "+_vm._s(_vm.$t("global.action.retry-and-run"))+" ")],1):_vm._e(),(_vm.$store.state.bpInstModifyPermission && !_vm.locked)?_c('v-btn',{attrs:{"color":"error","disabled":!_vm.allowedActions.canCancel},on:{"click":_vm.cancel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" pic-close ")]),_vm._v(" "+_vm._s(_vm.$t("global.action.cancel"))+" ")],1):_vm._e()],1),_c('v-spacer'),_c('v-col',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("global.business-process.task") + ": "))]),_c('router-link',{staticClass:"ooliba-font-color-blue",attrs:{"to":{
          name: 'Process task',
          params: { taskId: _vm.id },
        }}},[_vm._v(_vm._s(_vm.name + " (v." + (_vm.version + 1) + ")"))])],1)],1),(_vm.active)?_c('div',[(_vm.taskType === 'runCreation')?_c('task-run-list',{attrs:{"flowTaskId":_vm.flowTaskId}}):_vm._e(),(_vm.taskType === 'fileUpload')?_c('file-upload-task',{attrs:{"fileEnv":_vm.fileEnv,"flowTaskId":_vm.flowTaskId}}):_vm._e(),(_vm.taskType === 'evaluation')?_c('evaluation-task',{attrs:{"flowTaskId":_vm.flowTaskId}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }