export default {
  toolbar: {
    undo: "Undo",
    redo: "Redo",
    print: "Print",
    paintformat: "Paint format",
    clearformat: "Clear format",
    format: "Format",
    fontName: "Font",
    fontSize: "Font size",
    fontBold: "Font bold",
    fontItalic: "Font italic",
    underline: "Underline",
    strike: "Strike",
    color: "Text color",
    bgcolor: "Fill color",
    border: "Borders",
    merge: "Merge cells",
    align: "Horizontal align",
    valign: "Vertical align",
    textwrap: "Text wrapping",
    freeze: "Freeze cell",
    autofilter: "Filter",
    formula: "Functions",
    more: "More",
  },
  contextmenu: {
    copy: "Copier",
    cut: "Couper",
    paste: "Coller",
    pasteValue: "Coller valeur seulement",
    pasteFormat: "Coller mise en forme seulement",
    hide: "Cacher",
    insertRow: "Insertion ligne",
    insertColumn: "Insertion colonne",
    deleteSheet: "Supprimer",
    deleteRow: "Supprimer ligne",
    deleteColumn: "Supprimer colonne",
    deleteCell: "Supprimer cellule",
    deleteCellText: "Effacer le contenu",
    validation: "Data validations",
    cellprintable: "Enable export",
    cellnonprintable: "Disable export",
    celleditable: "Enable editing",
    cellnoneditable: "Disable editing",
  },
  print: {
    size: "Paper size",
    orientation: "Page orientation",
    orientations: ["Landscape", "Portrait"],
  },
  format: {
    normal: "Normal",
    text: "Plain Text",
    number: "Number",
    percent: "Percent",
    rmb: "RMB",
    usd: "USD",
    eur: "EUR",
    date: "Date",
    time: "Time",
    datetime: "Date time",
    duration: "Duration",
  },
  formula: {
    sum: "SUM",
    average: "AVERAGE",
    max: "MAX",
    min: "MIN",
    _if: "IF",
    and: "AND",
    or: "OR",
    concat: "CONCAT",
    now: "NOW",
    counta: "COUNTA",
  },
  validation: {
    required: "it must be required",
    notMatch: "it not match its validation rule",
    between: "it is between {} and {}",
    notBetween: "it is not between {} and {}",
    notIn: "it is not in list",
    equal: "it equal to {}",
    notEqual: "it not equal to {}",
    lessThan: "it less than {}",
    lessThanEqual: "it less than or equal to {}",
    greaterThan: "it greater than {}",
    greaterThanEqual: "it greater than or equal to {}",
  },
  error: {
    pasteForMergedCell: "Unable to do this for merged cells",
  },
  calendar: {
    weeks: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
  button: {
    next: "Next",
    cancel: "Cancel",
    remove: "Remove",
    save: "Save",
    ok: "OK",
  },
  sort: {
    desc: "Sort Z -> A",
    asc: "Sort A -> Z",
  },
  filter: {
    empty: "empty",
  },
  dataValidation: {
    mode: "Mode",
    range: "Cell Range",
    criteria: "Criteria",
    modeType: {
      cell: "Cell",
      column: "Colun",
      row: "Row",
    },
    type: {
      list: "List",
      number: "Number",
      date: "Date",
      phone: "Phone",
      email: "Email",
    },
    operator: {
      be: "between",
      nbe: "not between",
      lt: "less than",
      lte: "less than or equal to",
      gt: "greater than",
      gte: "greater than or equal to",
      eq: "equal to",
      neq: "not equal to",
    },
  },
};
