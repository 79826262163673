// ----------------------------------------------------------------------------
// Fonctions génériques de manipulation de tableaux
// ----------------------------------------------------------------------------
/**
 * Renvoie le dernier élément du tableau ou `undefined` si le tableau est vide.
 * Le tableau n'est pas modifié.
 *
 * Équivalent en plus lisible de`myarray[myarray.length - 1]`.
 *
 * @param {Array} a - `Array<Any>`
 * @returns `Any | undefined`
 */
export const arrayGetLast = (a) => a[a.length - 1];

/**
 * Trie le contenu du tableau sans modifier le tableau.
 *
 * Pour éviter les effets de bords induits par l'usage
 * de la méthode standard `Array.sort`,
 * qui change l'ordre des éléments dans le tableau
 * sur lequel elle est appellée, au lieu de renvoyer
 * une copie du tableau avec les mêmes éléments
 * dans l'ordre demandé.
 *
 * @param {Array} a Données à trier.
 * @param {Function} sortFn Fonction de tri
 *
 * * Paramètres : Soient `x` et `y` deux éléments de `a`
 * * Résultat :
 *    * `-1` si `x` doit être placé avant `y`
 *    * `+1` si `x` doit être placé après `y`
 *    * `0` si leur ordre relatif n'a pas d'importance (`x == y`)
 * @returns {Array} Une copie triée de `a`.
 */
export const arrayPureSort = (a, sortFn) => a.map((v) => v).sort(sortFn);

// Renvoie un booléen indiquant si les deux tableaux ont le même contenu (dans le même ordre)
export const arraysHaveSameContent = (a1, a2) => {
  return a1.length === a2.length && a1.every((v, i) => a2[i] === v);
};

/**
 * Indique si au moins une valeur est présente dans les deux tableaux
 * (fonction non récursive).
 * @param a1 Array
 * @param a2 Array
 * @return bool
 */
export const arraysHaveIntersection = (a1, a2) => {
  return a1.find((o) => a2.includes(o)) !== undefined;
};

/**
 * Renvoie la liste des valeurs présentes dans les deux tableaux.
 *
 * Pour tester si les tableaux ont des valeurs communes,
 * utiliser plutôt `arraysHaveIntersection`.
 * @param a1 Array
 * @param a2 Array
 * @returns Array
 */
export const arraysIntersection = (a1, a2) => {
  return a1.filter((o) => a2.includes(o));
};

// Renvoie une copie du premier tableau sans les éléments contenus dans le second tableau
export const arrayMinus = (array, itemsToRemove) => {
  return array.filter((o) => !itemsToRemove.includes(o));
};

// Renvoie une copie du tableau sans l'élément indiqué
export const arrayWithoutItem = (array, item) => {
  return array.filter((o) => o !== item);
};

// Renvoie la liste des valeurs présentes dans le tableau sans doublons
// ex : [1, 2, 3, 2, 1] => [1, 2, 3]
export const toUniqueArray = (array) => {
  const uniqueArray = array.filter((line, index) => {
    const lineToString = JSON.stringify(line);
    const hasTheSameIndex =
      index ===
      array.findIndex((line2) => {
        return JSON.stringify(line2) === lineToString;
      });

    return hasTheSameIndex;
  });
  return uniqueArray;
};

// 1st parameter: an array of objects
// 2nd parameter: a property of the objects
// return an array where there is only one object with the same property value
export const removeDuplicate = (source, prop) => {
  const target = [];
  source.forEach((sourceItem) =>
    target.filter((uniqItem) => uniqItem[prop] === sourceItem[prop]).length > 0
      ? false
      : target.push(sourceItem)
  );
  return target;
};

// 1st parameter: a list of objects
// 2nd parameter: a property of the objects
// return the list by ascending value of the property
export const sortListByAsc = (list, prop) => {
  list.sort((a, b) => (a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0));
};

// Reçoit un tableau contenant des nombres et en renvoie la somme
export function sumArray(numbers) {
  return numbers.reduce((a, b) => a + b, 0);
}

// Reçoit un tableau d'objets ainsi que le nom d'une de leur propriété (numérique)
// et en renvoie la somme.
export function sumArrayProp(objects, propName) {
  return objects.reduce((a, b) => a + b[propName], 0);
}
