// import { numberCalc } from 'x-data-spreadsheet/src/core/helper'
import { numberCalc } from "./helper";
import { tf } from "x-data-spreadsheet/src/locale/locale";

/**
  formula:
    key
    title
    render
*/
/**
 * @typedef {object} Formula
 * @property {string} key
 * @property {function} title
 * @property {function} render
 */
/** @type {Formula[]} */
// [FIX][formula.js] Fix formula order by alphabetical
const baseFormulas = [
  {
    key: "AND",
    title: tf("formula.and"),
    render: (ary) => ary.every((it) => it),
  },
  {
    key: "AVERAGE",
    title: tf("formula.average"),
    render: (ary) =>
      ary.reduce((a, b) => Number(a) + Number(b), 0) / ary.length,
  },
  {
    key: "CONCAT",
    title: tf("formula.concat"),
    render: (ary) => ary.join(""),
  },
  // [FIX][formula.js.baseFormulas, locale]: Add now COUNTA
  {
    key: "COUNTA",
    title: tf("formula.counta"),
    render: (ary) => {
      const numberOfCellFilled = ary.reduce((previousValue, currentValue) => {
        const isFill = currentValue !== '""' && currentValue !== "";
        return isFill ? previousValue + 1 : previousValue;
      }, 0);
      return numberOfCellFilled;
    },
  },
  {
    key: "IF",
    title: tf("formula._if"),
    render: ([b, t, f]) => {
      // console.log('IF', b, t, f, b ? t : f)
      return b ? t : f;
    },
  },
  {
    key: "MAX",
    title: tf("formula.max"),
    render: (ary) => Math.max(...ary.map((v) => Number(v))),
  },
  {
    key: "MIN",
    title: tf("formula.min"),
    render: (ary) => Math.min(...ary.map((v) => Number(v))),
  },
  // [FIX][formula.js.baseFormulas, locale]: Add NOW formula
  {
    key: "NOW",
    title: tf("formula.now"),
    render: () => {
      return new Date().toISOString().slice(0, 10);
    },
  },
  {
    key: "OR",
    title: tf("formula.or"),
    render: (ary) => ary.some((it) => it),
  },
  {
    key: "SUM",
    title: tf("formula.sum"),
    render: (ary) => ary.reduce((a, b) => numberCalc("+", a, b), 0),
  },

  /* support:  1 + A1 + B2 * 3
   {
     key: 'DIVIDE',
     title: tf('formula.divide'),
     render: ary => ary.reduce((a, b) => Number(a) / Number(b)),
   },
   {
     key: 'PRODUCT',
     title: tf('formula.product'),
     render: ary => ary.reduce((a, b) => Number(a) * Number(b),1),
   },
   {
     key: 'SUBTRACT',
     title: tf('formula.subtract'),
     render: ary => ary.reduce((a, b) => Number(a) - Number(b)),
   },
   */
];

const formulas = baseFormulas;

// const formulas = (formulaAry = []) => {
//   const formulaMap = {};
//   baseFormulas.concat(formulaAry).forEach((f) => {
//     formulaMap[f.key] = f;
//   });
//   return formulaMap;
// };
const formulam = {};
baseFormulas.forEach((f) => {
  formulam[f.key] = f;
});

export default {};

export { formulam, formulas, baseFormulas };
