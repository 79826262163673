<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="name"
          :label="$t('global.concepts.name') + '*'"
          :disabled="disabled"
          :rules="[notEmptyRule, noTagsRule]"
          outlined
          persistent-placeholder
          @input="onInput()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          auto-select-first
          :items="types"
          :label="$t('global.concepts.type') + '*'"
          v-model="values.type"
          :disabled="isEdit || disabled"
          :rules="[notEmptyRule]"
          outlined
          persistent-placeholder
          @input="onInput()"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <create-run-form-fields
      v-if="values.type === 'runCreation'"
      :init-values="initValues"
      @input="onRunFieldsInput($event)"
      :disabled="disabled"
    ></create-run-form-fields>
    <v-row v-else-if="values.type === 'fileUpload'">
      <v-col cols="6">
        <v-textarea
          v-model="values.fileNames"
          :label="$t('global.concepts.file-names')"
          :disabled="disabled"
          outlined
          :rules="[noTagsRule]"
          persistent-placeholder
          @input="onInput()"
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row v-else-if="values.type === 'evaluation'">
      <v-col cols="6">
        <v-text-field
          v-model="values.evaluationMessage"
          :label="$t('global.concepts.message')"
          :disabled="disabled"
          :rules="[noTagsRule]"
          outlined
          persistent-placeholder
          @input="onInput()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="values.type === 'fileUpload' || values.type === 'evaluation'">
      <v-col cols="6">
        <v-autocomplete
          auto-select-first
          :items="roles"
          chips
          multiple
          outlined
          :disabled="disabled"
          :label="$t('global.concepts.responsibles')"
          v-model="values.responsibles"
          persistent-placeholder
          @input="onInput()"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CreateRunFormFields from "@/components/run/CreateRunFormFields";
import { get } from "@/model/api";
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "ProcessTaskFormFields",
  components: { CreateRunFormFields },
  props: {
    disabled: Boolean,
    initValues: Object,
    isEdit: Boolean,
  },
  data() {
    return {
      values: {},

      // We don't include the name in the values object because having the v-model on values.name didn't always behave as expected
      name: undefined,

      types: [
        {
          text: this.$t("global.business-process.runCreation"),
          value: "runCreation",
        },
        {
          text: this.$t("global.business-process.fileUpload"),
          value: "fileUpload",
        },
        {
          text: this.$t("global.business-process.evaluation"),
          value: "evaluation",
        },
      ],

      notEmptyRule: notEmpty(this),
      noTagsRule: noTags(this),
      roles: [],
    };
  },

  watch: {
    initValues: {
      immediate: true,
      deep: true,

      handler(newInitValues) {
        this.values = {};
        Object.assign(this.values, newInitValues);
        this.name = newInitValues.name;
      },
    },
  },

  methods: {
    onInput() {
      this.values.name = this.name;
      this.$emit("input", this.values);
    },

    async getAllRoles() {
      return await get("/role").catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    onRunFieldsInput(data) {
      Object.assign(this.values, data);
      this.onInput();
    },
  },

  async created() {
    const roles = await this.getAllRoles();

    roles.forEach((element) => {
      this.roles.push(element.name);
    });
  },
};
</script>
