<template>
  <module-template
    id="scrolltainer"
    title
    :canEdit="$store.state.runRenamePermission"
    canList
    :canCopy="$store.state.runCreatePermission"
    :canDelete="$store.state.runDeletePermission && !run?.lockUser"
    :canExport="canExportOlp"
    @copyItem="copyRun"
    @deleteItem="deleteRun"
    @editItem="editRun"
    @list="listRuns"
    @export="onExportOlp"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.run.run") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div class="mr-2" v-if="run">
          {{ "#" + run?.id }}
        </div>
        <v-text-field
          dark
          v-model="editingName"
          type="text"
          v-if="showEdit"
          style="width: 400px"
          :rules="[noTagsRule]"
        >
          <template #append-outer
            ><v-btn
              @click="onEditConfirm"
              class="mr-1"
              dark
              fab
              outlined
              small
              :disabled="!canEditName"
              ><v-icon>pic-check</v-icon></v-btn
            >
            <v-btn @click="showEdit = false" dark fab outlined small
              ><v-icon>pic-close</v-icon></v-btn
            >
          </template></v-text-field
        >
        <div v-else>{{ run?.title }}</div>
      </v-row>
    </template>

    <v-row dense>
      <v-col>
        <basic-card-horizontal
          :headers="infoCardHeaders"
          :item="infoCardItem"
          v-if="run"
          min-width="600"
        >
          <template #[`item.bpInstance`]="{ item }">
            <span v-if="item.bpInstance">
              <v-chip class="mb-1" color="orange" dark small>
                {{ item.bpInstance[0] }}
              </v-chip>
              <router-link :to="bpRoute"> {{ item.bpInstance[1] }}</router-link>
              <v-tooltip v-if="run?.lockUser" top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="ml-1"
                    >pic-lock-fill</v-icon
                  >
                </template>
                <span>{{ lockMessage }}</span>
              </v-tooltip>
            </span>
          </template>
          <template #[`item.task`]="{ item }">
            <router-link :to="taskRoute">{{ item.task }}</router-link>
          </template>
          <template #[`item.env`]="{ item }">
            <router-link :to="envRoute"> {{ item.env }} </router-link>
          </template>
          <template #[`item.createdByName`]="{ item }">
            <router-link :to="createdByRoute">{{
              item.createdByName
            }}</router-link>
          </template>
        </basic-card-horizontal>
      </v-col>

      <v-col>
        <basic-card-horizontal
          :headers="propsCardHeaders"
          :item="propsCardItem"
          class="mb-2"
          min-width="600"
          v-if="run"
        />

        <basic-card-horizontal
          :headers="statusCardHeaders"
          :item="statusCardItem"
          v-if="run"
        >
          <template #[`item.status`]="{ item }">
            <v-row dense align="center">
              <v-col cols="5">
                <v-tooltip top v-if="item.missingProps">
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-if="item.statusColor"
                      style="margin: 2px 0px"
                      :color="item.statusColor"
                      dark
                      small
                      v-on="on"
                    >
                      {{ item.translatedStatus }}
                    </v-chip>
                    <span v-else>{{ item.translatedStatus }}</span>
                  </template>
                  <span style="font-size: 12px">{{ item.missingProps }}</span>
                </v-tooltip>
                <div v-else>
                  <v-chip
                    v-if="item.statusColor"
                    style="margin: 2px 0px"
                    :color="item.statusColor"
                    dark
                    small
                  >
                    {{ item.translatedStatus }}
                  </v-chip>
                  <span v-else>{{ item.translatedStatus }}</span>
                </div>
              </v-col>
              <v-col>
                <div v-if="item.showLoader" class="loader"></div>
              </v-col>
            </v-row>
          </template>

          <template #[`item.progress`]="{ item }">
            <v-row v-if="item.progress">
              <v-col>
                <v-progress-linear
                  rounded
                  height="18"
                  :value="item.progress"
                  color="light-blue"
                >
                </v-progress-linear>
              </v-col>
              <v-col cols="3">
                {{ item.progressText }}
              </v-col>
            </v-row>
            <span v-else> </span>
          </template>
        </basic-card-horizontal>
      </v-col>
    </v-row>

    <v-overlay :value="run?.status === 'INIT'">
      <v-chip color="blue" dark>{{
        $t("global.run.updating-dependencies")
      }}</v-chip>
    </v-overlay>

    <!-- Action launch / cancel -->
    <v-row class="py-3" dense v-if="!run?.lockUser">
      <v-col cols="auto">
        <v-btn v-if="canStart" @click="onStartRun" color="primary">
          <v-icon left>pic-play-fill</v-icon>
          {{ $t("global.run.start-run") }}</v-btn
        >
      </v-col>

      <v-col cols="auto">
        <v-btn v-if="canCancel" @click="onCancelRun" color="error">
          <v-icon left>pic-close</v-icon>
          {{ $t("global.action.cancel") }}</v-btn
        >
      </v-col>
    </v-row>

    <!-- Run details -->
    <v-row dense>
      <v-col cols="12">
        <run-details v-if="run" :run="run" />
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import { downloadFile, get, put, post, remove } from "@/model/api";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";
import { noTags } from "@/model/rules";
import { formatDate } from "@/model/util";

import RunDetails from "@/components/run/RunDetails";
import ModuleTemplate from "@/components/layout/ModuleTemplate";

export default {
  name: "SimulationRun",

  components: {
    BasicCardHorizontal,
    ModuleTemplate,
    RunDetails,
  },

  data() {
    return {
      infoCardHeaders: [
        { text: this.$t("global.modules.module"), value: "module" },
        { text: this.$t("global.run.simulation"), value: "simulation" },

        {
          text: this.$t("global.business-process.task"),
          value: "task",
          class: "ooliba-font-color-blue",
        },

        { text: this.$t("global.concepts.created-on"), value: "dateCreated" },
        {
          text: this.$t("global.environment.environment"),
          value: "env",
          class: "ooliba-font-color-blue",
        },
        {
          text: this.$t("global.administration.owner"),
          value: "createdByName",
          class: "ooliba-font-color-blue",
        },

        {
          text: this.$t("global.business-process.business-process"),
          value: "bpInstance",
          class: "ooliba-font-color-blue",
        },
      ],

      statusCardHeaders: [
        { text: this.$t("global.concepts.status"), value: "status" },
        { text: this.$t("global.run.progress"), value: "progress" },
        { text: this.$t("global.run.run-time"), value: "runtime" },
      ],

      run: {},

      timerId: undefined,

      showEdit: false,
      editingName: undefined,

      noTagsRule: noTags(this),

      deleting: false,
    };
  },

  computed: {
    lockMessage() {
      const parsedDate = formatDate(this.run?.lockDate);

      return this.$t("global.business-process.lock-message", [
        parsedDate,
        this.run?.lockUser,
      ]);
    },

    canEditName() {
      return typeof this.noTagsRule(this.editingName) !== "string";
    },

    taskRoute() {
      if (!this.run?.taskId) {
        return {};
      }
      return {
        name: "Process task",
        params: { taskId: this.run.taskId },
      };
    },

    bpRoute() {
      if (!this.run?.bpId) {
        return {};
      }
      return {
        name: "BP instance",
        params: { bpId: this.run.bpId },
      };
    },

    envRoute() {
      if (!this.run?.parentFileEnvId && !this.run?.fileEnvId) {
        return {};
      }
      return {
        name: "File env",
        params: {
          fileEnvId: this.run.fileEnvIsLocal
            ? this.run.parentFileEnvId
            : this.run.fileEnvId,
        },
      };
    },

    createdByRoute() {
      if (!this.run?.createdBy) {
        return {};
      }
      return {
        name: "User",
        params: { userId: this.run.createdBy },
      };
    },

    canCancel() {
      const status = this.run?.status;
      const requestedStatus = this.run?.requestedStatus;

      return (
        this.$store.state.runCreatePermission &&
        requestedStatus !== "CANCEL_REQUESTED" &&
        (status == "WAITING_FOR_DATA" ||
          status == "WAITING_FOR_PROPS" ||
          status == "READY_TO_START" ||
          status == "WAITING_FOR_EVALUATION" ||
          status == "REJECTED_DATA" ||
          status == "INVALID_DATA" ||
          status == "COMPUTING" ||
          status == "INITIALIZING" ||
          status == "SCHEDULED" ||
          status == "RUNNING")
      );
    },

    canStart() {
      return (
        this.$store.state.runCreatePermission &&
        this.run?.status === "READY_TO_START" &&
        this.run?.requestedStatus !== "RUN_REQUESTED"
      );
    },

    canExportOlp() {
      return (
        this.$store.state.olpExportPermission && this.run?.status !== "INIT"
      );
    },

    infoCardItem() {
      if (!this.run) {
        return;
      }
      const envName = this.run.fileEnvIsLocal
        ? this.run.parentFileEnvName
        : this.run.fileEnvName;
      const envColor = this.run.fileEnvIsLocal
        ? this.run.parentFileEnvColor
        : this.run.fileEnvColor;

      return {
        module: this.run?.module + " (v." + this.run?.moduleVersion + ")",
        simulation: this.run?.simulation,
        createdByName: this.run.createdBy,
        dateCreated: formatDate(this.run.dateCreated),
        env: envName,
        envColor: envColor,
        task: this.run?.taskVersionName
          ? this.run?.taskVersionName +
            " (v." +
            (this.run?.taskVersionVersionNumber + 1) +
            ")"
          : "",
        bpInstance: this.run?.bpTitle
          ? ["#" + this.run?.bpId, this.run?.bpTitle]
          : null,
      };
    },

    statusCardItem() {
      if (!this.run) {
        return;
      }
      let status = this.run?.status;
      let currentTask =
        this.run.currentTask === 0 ? 0 : this.run.currentTask - 1;
      let numTasks = this.run.nrOfTasks;
      let statusColor = null;
      let showLoader = false;
      switch (status) {
        case "SUCCESS":
          statusColor = "green";
          break;
        case "ERROR":
          statusColor = "red";
          break;
        case "WAITING_FOR_DATA":
        case "WAITING_FOR_PROPS":
        case "READY_TO_START":
          statusColor = "gray";
          break;
        case "INIT":
        case "INITIALIZING":
        case "SCHEDULED":
        case "FINALIZING":
        case "COMPUTING":
          showLoader = true;
          break;
      }
      let translatedStatus = this.run.translatedStatus;
      let requestedStatus = this.run.requestedStatus;

      if (requestedStatus === "CANCEL_REQUESTED") {
        if (
          status !== "CANCELLED" &&
          status !== "SUCCESS" &&
          status !== "ERROR"
        ) {
          statusColor = null;
          showLoader = true;
        }
      } else if (requestedStatus === "RUN_REQUESTED") {
        if (status === "READY_TO_START") {
          statusColor = null;
          showLoader = true;
        }
      }

      let missingProps = undefined;
      if (this.run.missingProps) {
        missingProps = JSON.parse(this.run.missingProps).join(", ");
      }

      return {
        status: status,
        isRunning: status === "COMPUTING",
        progress: numTasks ? (100 * currentTask) / numTasks : "",
        progressText:
          currentTask || currentTask == 0 ? currentTask + "/" + numTasks : "",
        translatedStatus: translatedStatus,
        runtime: this.formatRunTime(this.run.runTime),
        statusColor: statusColor,
        showLoader: showLoader,
        missingProps: missingProps,
      };
    },

    propsCardHeaders() {
      if (this.run?.props) {
        return Object.keys(this.run.props).map((prop) => {
          return { text: prop, value: prop };
        });
      }
      return [];
    },

    propsCardItem() {
      if (!this.run) {
        return;
      }
      const item = {};
      this.propsCardHeaders.forEach((header) => {
        item[header.value] = this.run.props[header.value];
      });

      return item;
    },
  },

  async created() {
    const runId = this.$route.params.runId;
    if (!runId) return;

    this.run = await this.getRun(runId);
    this.editingName = this.run?.title;
    this.continuouslyUpdateRun();
  },

  destroyed() {
    clearTimeout(this.timerId);
  },

  methods: {
    formatRunTime(runTime) {
      if (!runTime) return;

      const roundedRunTime = Math.round(runTime);
      const hours = Math.floor(roundedRunTime / 3600);
      const minutes = Math.floor((roundedRunTime % 3600) / 60);
      const seconds = (roundedRunTime % 3600) % 60;

      let formatted = "";
      if (hours > 1) {
        formatted += hours + " " + this.$i18n.t("global.concepts.hours") + " ";
      } else if (hours > 0) {
        formatted += hours + " " + this.$i18n.t("global.concepts.hour") + " ";
      }
      if (minutes > 1) {
        formatted +=
          minutes + " " + this.$i18n.t("global.concepts.minutes") + " ";
      } else if (minutes > 0) {
        formatted +=
          minutes + " " + this.$i18n.t("global.concepts.minute") + " ";
      }
      if (seconds > 1 || seconds === 0) {
        formatted += seconds + " " + this.$i18n.t("global.concepts.seconds");
      } else if (seconds > 0) {
        formatted += seconds + " " + this.$i18n.t("global.concepts.second");
      }

      return formatted;
    },

    listRuns() {
      this.$router.push({ name: "Runs" });
    },

    onEditConfirm() {
      put(
        "/simulationRun/rename?runId=" +
          encodeURIComponent(this.run?.id) +
          "&name=" +
          encodeURIComponent(this.editingName)
      )
        .then((r) => {
          this.editingName = r;
          this.run.title = r;
          this.showEdit = false;
        })
        .catch((e) => {
          this.$store.commit("showError", e);
          this.showEdit = false;
        });
    },

    editRun() {
      this.showEdit = true;
    },

    async deleteRun() {
      if (!this.run.id) return;

      this.deleting = true;
      await remove("/simulationRun?id=" + this.run.id)
        .then(() => {
          this.$router.push({ name: "Runs" });
          this.deleting = false;
        })
        .catch((error) => {
          this.onError(error);
          this.deleting = false;
        });
    },

    async getRun(id) {
      if (!id) return;

      return await get("/simulationRun?id=" + id).catch((e) => this.onError(e));
    },

    async onCancelRun() {
      await post("/simulationRun/" + this.run.infoId + "/cancel").catch(
        (error) => this.onError(error)
      );
      this.run = await this.getRun(this.run.id);
    },

    async onExportOlp() {
      downloadFile("/simulationRun/" + this.run.infoId + "/export-olp").catch(
        (err) => {
          this.$store.commit("showError", err);
        }
      );
    },

    async onStartRun() {
      await get("/simulationRun/run?id=" + this.run.id).catch((error) =>
        this.onError(error)
      );
      this.run = await this.getRun(this.run.id);
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    continuouslyUpdateRun() {
      this.timerId = window.setTimeout(async () => {
        if (this.run?.id) {
          if (!this.deleting) {
            this.run = await this.getRun(this.run.id);
          }
          if (!this.$store.getters.isStatusFinished(this.run?.status)) {
            this.continuouslyUpdateRun();
          }
        }
      }, 1800);
    },

    copyRun() {
      this.$router.push({
        name: "Copy Run",
        params: { runId: this.$route.params.runId },
      });
    },
  },
};
</script>

<style>
.loader {
  width: 30px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }
  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }
  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}
</style>
