import { expr2xy, xy2expr } from "x-data-spreadsheet/src/core/alphabet";

import { numberCalc } from "./helper";

// Converting infix expression to a suffix expression
// src: AVERAGE(SUM(A1,A2), B1) + 50 + B20
// return: [A1, A2], SUM[, B1],AVERAGE,50,+,B20,+
const infixExprToSuffixExpr = (src, formulaMap) => {
  // [FIX][cell.js.infixExprToSuffixExpr]: Manage formula contening another formula - Mange formula with zero or one parameter
  const operatorStack = [];
  const stack = [];
  let subStrs = []; // SUM, A1, B2, 50 ...
  // let fnArgType = 0 // 1 => , 2 => :
  let fnArgType = []; // 1 => , 2 => :
  let fnArgOperator = "";
  // let fnArgsLen = 1 // A1,A2,A3...
  let fnArgsLen = []; // A1,A2,A3...
  let fnNbOpsBetweenComma = []; // PCRRV9-2089: brouillon pour corriger
  let fnArgsLvl = -1;
  fnArgsLen[fnArgsLvl] = 0;

  let oldc = "";

  for (let i = 0; i < src.length; i += 1) {
    const c = src.charAt(i);
    if (c !== " ") {
      if (c >= "a" && c <= "z") {
        subStrs.push(c.toUpperCase());
      } else if (
        (c >= "0" && c <= "9") ||
        (c >= "A" && c <= "Z") ||
        c === "."
      ) {
        subStrs.push(c);
      } else if (c === '"') {
        i += 1;
        while (src.charAt(i) !== '"') {
          subStrs.push(src.charAt(i));
          i += 1;
        }

        if (subStrs.length === 0) {
          stack.push('""');
        } else {
          stack.push(`"${subStrs.join("")}`);
        }
        // stack.push(`"${subStrs.join('')}`)

        subStrs = [];
      } else if (c === "-" && /[+\-*/,(]/.test(oldc)) {
        subStrs.push(c);
      } else {
        // console.log('subStrs:', subStrs.join(''), stack);
        if (c !== "(" && subStrs.length > 0) {
          stack.push(subStrs.join(""));
        }
        if (c === ")") {
          let c1 = operatorStack.pop();
          if (fnArgType[fnArgsLvl] === 2) {
            // fn argument range => A1:B5
            try {
              const [ex, ey] = expr2xy(stack.pop());
              const [sx, sy] = expr2xy(stack.pop());
              // console.log('::', sx, sy, ex, ey);
              let rangelen = 0;
              for (let x = sx; x <= ex; x += 1) {
                for (let y = sy; y <= ey; y += 1) {
                  stack.push(xy2expr(x, y));
                  rangelen += 1;
                }
              }
              stack.push([c1, rangelen]);
            } catch (e) {
              // console.log(e);
            }
            // } else if (fnArgType === 1 || fnArgType === 3) {
          } else if (fnArgType[fnArgsLvl] === 1 || fnArgType[fnArgsLvl] === 3) {
            if (fnArgType[fnArgsLvl] === 3) stack.push(fnArgOperator);
            // fn argument => A1,A2,B5
            const noComma = fnArgsLen[fnArgsLvl] === 0;
            if (noComma) {
              // check if the operator has 1 parameter => fnArgsLen[fnArgsLvl]++
              const openIndex = src.substring(0, i).lastIndexOf("(");
              const hasOneParameter =
                src.substring(openIndex + 1, i).trim() !== "";
              if (hasOneParameter) {
                fnArgsLen[fnArgsLvl]++;
              }
            }
            stack.push([c1, fnArgsLen[fnArgsLvl]]);
            // fnArgsLen = 1
          } else {
            // console.log('c1:', c1, fnArgType, stack, operatorStack);
            while (c1 !== "(") {
              stack.push(c1);
              if (operatorStack.length <= 0) break;
              c1 = operatorStack.pop();
            }
          }
          // fnArgType = 0
          fnArgType[fnArgsLvl] = 0;
          fnArgsLvl -= 1;
        } else if (c === "=" || c === ">" || c === "<") {
          const nc = src.charAt(i + 1);
          fnArgOperator = c;
          if (nc === "=" || nc === "-" || nc === ">") {
            // [FIX][cell.js[infixExprToSuffixExpr, evalSuffixExpr]]: Add <> operator
            fnArgOperator += nc;
            i += 1;
          }
          fnArgType[fnArgsLvl] = 3;
        } else if (c === ":") {
          fnArgType[fnArgsLvl] = 2;
        } else if (c === ",") {
          if (fnArgType[fnArgsLvl] === 3) {
            stack.push(fnArgOperator);
          }
          fnArgType[fnArgsLvl] = 1;
          // fnArgsLen += 1
          const isFirstComma = fnArgsLen[fnArgsLvl] === 0;
          if (isFirstComma) {
            fnArgsLen[fnArgsLvl] = fnArgsLen[fnArgsLvl] + 2;
          } else {
            fnArgsLen[fnArgsLvl]++;
          }
          /* PCRRV9-2089: brouillon pour corriger
          if (fnNbOpsBetweenComma[fnArgsLvl] > 0) {
            let i = 0
            while (i < fnNbOpsBetweenComma[fnArgsLvl]) {
              stack.push(operatorStack.pop())
              i++
            }
          }
          fnNbOpsBetweenComma[fnArgsLvl] = 0
          */
        } else if (
          c === "(" &&
          subStrs.length > 0 &&
          formulaMap[subStrs.join("")]
        ) {
          // function
          fnArgsLvl += 1;
          fnNbOpsBetweenComma[fnArgsLvl] = 0;
          fnArgsLen[fnArgsLvl] = 0; // set number of parameter to 0
          fnArgType[fnArgsLvl] = 1; // formula type (0 param or 1 param is managed)
          operatorStack.push(subStrs.join(""));
          /* } else if (c === '+' || c === '-' || c === '*' || c === '/') { // PCRRV9-2089: brouillon pour corriger
          operatorStack.push(c)
          if (fnArgsLen[fnArgsLvl] > 0) {
            fnNbOpsBetweenComma[fnArgsLvl]++
          }
        }
        */
        } else {
          if (c === "(") {
            // [FIX][cell.js.infixExprToSuffixExpr] correct FORMULAS((A1+A2),...)
            fnArgsLvl += 1; // add the level
            fnArgsLen[fnArgsLvl] = 0; // set number of parameter to 0
            fnArgType[fnArgsLvl] = 0; // set the type 0 (not a formulas)
          }
          // priority: */ > +-
          // console.log('xxxx:', operatorStack, c, stack);
          if (operatorStack.length > 0 && (c === "+" || c === "-")) {
            let top = operatorStack[operatorStack.length - 1];
            if (top !== "(") stack.push(operatorStack.pop());
            if (top === "*" || top === "/") {
              while (operatorStack.length > 0) {
                top = operatorStack[operatorStack.length - 1];
                if (top !== "(") stack.push(operatorStack.pop());
                else break;
              }
            }
          } else if (operatorStack.length > 0) {
            const top = operatorStack[operatorStack.length - 1];
            if (top === "*" || top === "/") stack.push(operatorStack.pop());
          }
          operatorStack.push(c);
        }
        subStrs = [];
      }
      oldc = c;
    }
  }
  if (subStrs.length > 0) {
    stack.push(subStrs.join(""));
  }
  while (operatorStack.length > 0) {
    stack.push(operatorStack.pop());
  }
  return stack;
};

const evalSubExpr = (subExpr, cellRender, cellsSrc) => {
  const [fl] = subExpr;
  let expr = subExpr;
  if (fl === '"') {
    return subExpr.substring(1);
  }
  let ret = 1;
  if (fl === "-") {
    expr = subExpr.substring(1);
    ret = -1;
  }
  if (expr[0] >= "0" && expr[0] <= "9") {
    return ret * Number(expr);
  }
  const [x, y] = expr2xy(expr);

  // [FIX][cell.js[evalSubExpr, evalSuffixExpr], helper.js [numberCalc]]: Add & operator
  const cellValue = cellRender(x, y, cellsSrc);
  if (cellValue === "" || isNaN(cellValue)) {
    return cellValue;
  }
  return ret * cellValue;
};

// evaluate the suffix expression
// srcStack: <= infixExprToSufixExpr
// formulaMap: {'SUM': {}, ...}
// cellRender: (x, y) => {}
const evalSuffixExpr = (
  srcStack,
  formulaMap,
  cellRender,
  cellList,
  cellsSrc
) => {
  const stack = [];
  // console.log(':::::formulaMap:', formulaMap);
  for (let i = 0; i < srcStack.length; i += 1) {
    // console.log(":::>>>", srcStack[i]);
    const expr = srcStack[i];
    const fc = expr[0];
    if (expr === "+") {
      if (stack.length === 1) {
        // [FIX][cell.js.evalSuffixExpr]: Fix case =+A1
        let top = stack.pop();
        stack.push(numberCalc("*", top, 1));
      } else {
        let top = stack.pop();
        stack.push(numberCalc("+", stack.pop(), top));
      }
    } else if (expr === "-") {
      if (stack.length === 1) {
        let top = stack.pop();
        stack.push(numberCalc("*", top, -1));
      } else {
        let top = stack.pop();
        stack.push(numberCalc("-", stack.pop(), top));
      }
    } else if (expr === "*") {
      stack.push(numberCalc("*", stack.pop(), stack.pop()));
    } else if (expr === "&") {
      // [FIX][cell.js[evalSubExpr, evalSuffixExpr], helper.js.numberCalc]: Add & operator
      stack.push(numberCalc("&", stack.pop(), stack.pop()));
    } else if (expr === "/") {
      let top = stack.pop();
      stack.push(numberCalc("/", stack.pop(), top));
    } else if (fc === "=" || fc === ">" || fc === "<") {
      let top = stack.pop();

      if (!Number.isNaN(top)) top = Number(top);
      let left = stack.pop();

      if (!Number.isNaN(left)) left = Number(left);
      let ret = false;
      if (fc === "=") {
        ret = left === top;
      } else if (expr === ">") {
        ret = left > top;
      } else if (expr === ">=") {
        ret = left >= top;
      } else if (expr === "<") {
        ret = left < top;
      } else if (expr === "<=") {
        ret = left <= top;
      } else if (expr === "<>") {
        // [FIX][cell.js[infixExprToSuffixExpr, evalSuffixExpr]]: Add <> operator
        ret = left !== top;
      }
      stack.push(ret);
    } else if (Array.isArray(expr)) {
      const [formula, len] = expr;
      const params = [];
      for (let j = 0; j < len; j += 1) {
        params.push(stack.pop());
      }

      const mappedFormula = formulaMap[formula];
      if (!mappedFormula) {
        throw new Error(`Formula '${formula}' not found in formulaMap`);
      }
      stack.push(mappedFormula.render(params.reverse()));
    } else if (expr === '""') {
      // [FIX][cell.js.evalSuffixExpr] Fix empty cell in formula
      stack.push("");
    } else {
      if (cellList.includes(expr)) {
        return 0;
      }
      if ((fc >= "a" && fc <= "z") || (fc >= "A" && fc <= "Z")) {
        cellList.push(expr);
      }
      let childCellsSrc = [...cellsSrc];
      childCellsSrc.push(expr);
      stack.push(evalSubExpr(expr, cellRender, childCellsSrc));
      cellList.pop();
    }
    // console.log('stack:', stack);
  }
  return stack[0];
};

const cellRender = (
  src,
  formulaMap,
  getCellText,
  cellList = [],
  cellsSrc = [],
  formulaValue
) => {
  if (formulaValue || "" === formulaValue) {
    // We don't evaluate the formula and simply return the result, because the support for formulas is currently too limited
    return formulaValue;
  }
  if (src[0] === "=") {
    // [FIX][table.js][cell.js] PCRRV9-2414: Add checking for circular dependance
    let hasCircularDep = false;
    for (let i = 0; i < cellsSrc.length; i++) {
      let rootCell = cellsSrc[i];
      hasCircularDep = hasCircularDep || cellsSrc.includes(rootCell, i + 1);
    }
    if (hasCircularDep) {
      // console.log(`[${cellsSrc.join('-')}] ${src}`)
      return "FORMULA_CONTAINS_CIRCULAR";
    }

    const stack = infixExprToSuffixExpr(src.substring(1), formulaMap);
    // console.log('cellRender formula_reverse:', stack)

    if (stack.length <= 0) return src;
    try {
      return evalSuffixExpr(
        stack,
        formulaMap,
        (x, y, childCellsSrc) =>
          cellRender(
            getCellText(x, y),
            formulaMap,
            getCellText,
            cellList,
            childCellsSrc
          ),
        cellList,
        cellsSrc
      );
    } catch (err) {
      // console.error("Error in cellRender", err);
      return "FORMULA_NOT_IMPLEMENTED";
    }
  }
  return src;
};

export default {
  render: cellRender,
};
export { infixExprToSuffixExpr };
