<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          auto-select-first
          v-if="values"
          :items="moduleNames"
          :label="$t('global.modules.module') + '*'"
          :rules="[notEmptyRule]"
          v-model="values.module"
          @change="onModuleInput()"
          :disabled="disabled || isCopy"
          outlined
          persistent-placeholder
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          auto-select-first
          :items="moduleVersionStrings"
          :label="$t('global.concepts.version')"
          v-model="values.moduleVersion"
          @change="onModuleVersionInput()"
          :disabled="disabled"
          outlined
          persistent-placeholder
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          auto-select-first
          :items="simulations"
          :label="$t('global.run.simulation') + '*'"
          :rules="[notEmptyRule]"
          v-model="values.simulation"
          @change="onSimulationInput()"
          :disabled="disabled"
          outlined
          persistent-placeholder
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          :label="$t('global.concepts.title') + '*'"
          :rules="[notEmptyRule, noTagsRule]"
          v-model="values.title"
          :disabled="disabled"
          outlined
          persistent-placeholder
          @input="onInput()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <PropertyForm
      v-if="propertyForm && showForm"
      :form-rows="propertyForm"
      @input="onFormInput($event)"
    />
    <v-row align="center">
      <v-col cols="2">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-checkbox
                :label="$t('global.run.auto-publish')"
                v-model="values.autoPublish"
                :input-value="true"
                :disabled="disabled"
                @change="onInput()"
              ></v-checkbox>
            </span>
          </template>
          <span>{{ $t("global.msg.auto-publish-details") }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          :label="$t('global.run.auto-start')"
          v-model="values.startRun"
          :input-value="true"
          :disabled="disabled"
          @change="onInput()"
        ></v-checkbox>
      </v-col>

      <v-col cols="2">
        <v-checkbox
          v-if="isCopy"
          :label="$t('global.run.keep-versions')"
          v-model="values.keepFileVersions"
          :input-value="true"
          :disabled="disabled"
          @change="onInput()"
        ></v-checkbox>
      </v-col>

      <v-col cols="2">
        <basic-switch
          v-model="showProperties"
          dense
          :label="
            disabled
              ? $t('global.concepts.show-props')
              : $t('global.concepts.add-props')
          "
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-textarea
          v-if="showProperties"
          :label="$t('global.property.properties')"
          v-model="values.propertiesText"
          :disabled="disabled"
          outlined
          :rules="[noTagsRule]"
          persistent-placeholder
          @input="onInput()"
        >
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get, post } from "@/model/api";
import PropertyForm from "@/components/PropertyForm.vue";
import BasicSwitch from "@/components/BasicSwitch";
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "CreateRunFormFields",
  components: {
    PropertyForm,
    BasicSwitch,
  },

  props: {
    disabled: Boolean,
    showForm: Boolean,
    isEdit: Boolean,
    initValues: Object,
    initProperties: String,
    isCopy: Boolean,
  },

  data() {
    return {
      moduleNames: [],
      moduleVersionStrings: [],
      moduleVersions: [],
      simulations: [],
      values: {
        propertyForm: {},
      },
      propertyForm: [],
      showProperties: false,
      notEmptyRule: notEmpty(this),
      noTagsRule: noTags(this),
    };
  },
  watch: {
    disabled(disabled) {
      // This gets triggered when the user clicks the the edit button in the proces task versions list
      if (!disabled) {
        this.load();
      }
    },

    initValues: {
      // This gets triggered when the user clicks the different versions in the proces task versions list
      immediate: true,
      handler(newInitValues) {
        this.values = {};
        Object.assign(this.values, newInitValues);
        this.moduleNames = [this.values.module];
        this.moduleVersionStrings = [this.values.moduleVersion];
        this.simulations = [this.values.simulation];
      },
    },
  },
  methods: {
    onInput: function () {
      this.$emit("input", this.values);
    },

    async onModuleInput() {
      if (!this.disabled && this.values.module) {
        this.moduleVersions = await this.getReleasesForModule(
          this.values.module
        );
        if (this.moduleVersions) {
          this.moduleVersionStrings = this.getVersionStrings(
            this.moduleVersions
          );
        }
        this.values.moduleVersion = undefined;
        this.onModuleVersionInput();
        this.onInput();
      }
    },

    async onModuleVersionInput() {
      if (
        !this.disabled &&
        this.moduleVersionStrings.length > 1 &&
        this.moduleVersions
      ) {
        const selectedVersionString =
          this.values.moduleVersion ?? this.moduleVersionStrings[1];
        const version = this.moduleVersions.find(
          (version) => version.versionString === selectedVersionString
        );
        if (version) {
          this.simulations = await this.getSimulationsForRelease(version.id);
        }
        this.onInput();
      }
    },

    async onSimulationInput() {
      this.$emit("not ready");
      if (!this.disabled && this.values.simulation) {
        if (!this.values.title) {
          this.$set(this.values, "title", this.values.simulation);
        }
        if (this.values.module && this.showForm) {
          this.propertyForm = await this.getPropertyForm(
            this.values.module,
            this.values.moduleVersion,
            this.values.simulation
          );
        }
        this.onInput();
      }
      this.$emit("ready");
    },

    setInitPropValues() {
      const initPropsSplit = this.initProperties.split("\n");
      let remainingProps = "";

      for (let i = 0; i < initPropsSplit.length; i++) {
        const splitProp = initPropsSplit[i].split("=");

        if (splitProp.length === 2) {
          const matchingProp = this.propertyForm.find(
            (prop) => prop.key === splitProp[0]
          );
          if (matchingProp) {
            matchingProp.defaultValue = splitProp[1];
          } else {
            remainingProps += "\n" + initPropsSplit[i];
          }
          this.values.propertiesText = remainingProps;
        }
      }
    },

    async getSimulationsForRelease(releaseId) {
      return await get("release/simulations/" + releaseId).catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    getVersionStrings(versions) {
      const moduleVersions = [];

      moduleVersions.push("");

      versions.forEach((version) => {
        moduleVersions.push(version.versionString);
      });

      return moduleVersions;
    },

    async getModules() {
      return await get("release/latest-versions").catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    async getReleasesForModule(module) {
      return await get("release/versions/" + module).catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    async load() {
      this.$emit("not ready");
      const modules = await this.getModules();

      if (modules) {
        this.moduleNames = [];

        modules.forEach((element) => {
          this.moduleNames.push(element.moduleName);
        });
      }

      if (this.initValues.module) {
        this.moduleVersions = await this.getReleasesForModule(
          this.values.module
        );
        if (this.moduleVersions) {
          this.moduleVersionStrings = this.getVersionStrings(
            this.moduleVersions
          );
        }

        const selectedVersionString =
          this.initValues.moduleVersion ?? this.moduleVersionStrings[1];
        const version = this.moduleVersions.find(
          (version) => version.versionString === selectedVersionString
        );
        if (version) {
          this.simulations = await this.getSimulationsForRelease(version.id);
        }

        if (
          this.initValues.module &&
          this.showForm &&
          this.initValues.simulation
        ) {
          this.propertyForm = await this.getPropertyForm(
            this.initValues.module,
            this.initValues.moduleVersion,
            this.initValues.simulation
          );
        }
      }

      if (this.initProperties) {
        this.setInitPropValues();
      }
      this.$emit("ready");
    },

    async getPropertyForm(module, moduleVersion, simulation) {
      const data = {
        module: module,
        moduleVersion: moduleVersion,
        simulation: simulation,
      };
      return post("simulationRun/run-form", data).catch((error) => {
        this.$store.commit("showError", error);
      });
    },
    onFormInput(formValues) {
      this.values.propertyForm = formValues;
      this.onInput();
    },
  },
  async created() {
    if (!this.disabled) {
      this.load();
    }
  },
};
</script>
