<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.environment-properties')"
    canCreate
    :createToolTip="
      $t('global.header.newFem', [
        $t('global.property.envProperty').toLowerCase(),
      ])
    "
    @newItem="addProperty"
  >
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      sort-by="name"
      no-pagination
      :height="tableHeight(85, items?.length)"
    >
      <template #[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editProperty(item)"
              v-bind="attrs"
              v-on="on"
            >
              pic-edit
            </v-icon>
          </template>
          <span>{{ $t("global.action.edit") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="red"
              @click="deleteConfirm(item)"
              v-bind="attrs"
              v-on="on"
            >
              pic-delete
            </v-icon>
          </template>
          <span>{{ $t("global.tooltip.effisoftToolbarDelete") }}</span>
        </v-tooltip>
      </template>
    </ooliba-basic-table>

    <v-dialog v-model="showDeleteConfirm" width="500">
      <confirm-alert
        @cancel="cancelDelete"
        @confirm="deleteProperty"
      ></confirm-alert>
    </v-dialog>
    <v-dialog v-model="showAddEdit" width="500">
      <add-edit-property
        v-if="showAddEdit"
        @done="onAddEditDone"
        @cancel="onAddEditCancel"
        :isEdit="isEdit"
        :initProperty="editingProperty"
      />
    </v-dialog>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { get, remove } from "@/model/api";
import ConfirmAlert from "@/components/ConfirmAlert";
import AddEditProperty from "@/components/AddEditProperty";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import { tableHeight } from "@/model/util";

export default {
  name: "PropertyList",
  components: {
    ModuleTemplate,
    ConfirmAlert,
    AddEditProperty,
    OolibaBasicTable,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.concepts.value"), value: "value" },
        {
          text: this.$t("global.concepts.actions"),
          value: "action",
          sortable: false,
        },
      ],
      items: [],
      isEdit: false,
      editingProperty: {},
      deletingProperty: {},
      showAddEdit: false,
      properties: [],
      showDeleteConfirm: false,

      busy: true,
    };
  },
  methods: {
    tableHeight,

    async getProperties() {
      return await get("/environment-property").catch((error) => {
        this.$store.commit("showError", error);
      });
    },
    async deleteProperty() {
      await remove("/environment-property/" + this.deletingProperty.id)
        .then(() => {
          this.updateTable();
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
      this.showDeleteConfirm = false;
    },
    deleteConfirm(row) {
      this.deletingProperty = row;
      this.showDeleteConfirm = true;
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
    },
    async updateTable() {
      this.busy = true;
      this.items = [];
      this.props = await this.getProperties();

      if (this.props) {
        this.props.forEach((value) => {
          const item = {};

          item["id"] = value.id;
          item[this.headers[0].value] = value.name;
          item[this.headers[1].value] = value.value;

          this.items.push(item);
        });
      }
      this.busy = false;
    },
    async onAddEditDone() {
      this.showAddEdit = false;

      await this.updateTable();
    },
    async onAddEditCancel() {
      this.showAddEdit = false;
    },
    addProperty() {
      this.isEdit = false;
      this.editingProperty = {};
      this.showAddEdit = true;
    },
    editProperty(row) {
      this.isEdit = true;
      this.editingProperty = row;
      this.showAddEdit = true;
    },
  },

  async mounted() {
    await this.updateTable();
  },
};
</script>
